import { Component } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import {
  MatDialogContent,
  MatDialogModule,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog'
import { environment } from 'environments/environment'

@Component({
  selector: 'app-maintenance-popup',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatDialogTitle, MatDialogContent],
  templateUrl: './maintenance-popup.component.html',
  styleUrl: './maintenance-popup.component.scss',
})
export class MaintenancePopupComponent {
  appName = environment.appName
  maintenanceDates = environment.maintenanceNotice

  constructor(private _dialogRef: MatDialogRef<MaintenancePopupComponent>) {}

  closeOnce() {
    this._dialogRef.close(false)
  }

  closeDefinitively() {
    this._dialogRef.close(false)
    localStorage.setItem('maintenanceNoticeRead', JSON.stringify(environment.maintenanceNotice)) // Store the dates of the maintenance to recognize it and avoid showing warning again
  }
}
