import { CommonModule, DecimalPipe } from '@angular/common'
import { Component, computed, effect, OnInit, signal, ViewChild } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialog, MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { MatTableModule } from '@angular/material/table'
import { MatTooltipModule } from '@angular/material/tooltip'
import { BaseClass } from '@core/bases/base.class'
import { UploadedFile } from '@core/models/upload.class'
import { LayersService } from '@core/services/layers.service'
import { UploadService } from '@core/services/upload.service'
import { environment } from '@env/environment'
import { FormatSharedWithPipe } from '@shared/pipes/format-shared-with.pipe'
import { takeUntil } from 'rxjs'
import { FileManagementComponent } from '../file-management/file-management.component'
import { LayerDeleteConfirmPopupComponent } from './layer-delete-confirm-popup/layer-delete-confirm-popup.component'

@Component({
  standalone: true,
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css'],
  imports: [
    CommonModule,
    DecimalPipe,
    FormatSharedWithPipe, // used in .ts
    //Forms
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    // Material
    MatTooltipModule,
    MatIconModule,
    MatTableModule,
    MatButtonModule,
  ],
})
export class UploadComponent extends BaseClass implements OnInit {
  @ViewChild('inputFile2', { static: true }) inputFile2
  @ViewChild('inputFile', { static: true }) inputFile
  localhost: boolean = environment.apiUrl.includes('localhost')

  emailShareList$ = signal('')
  parsedEmailList = computed(() => {
    const emailList = this.emailShareList$()
    //@todo add email format validation
    return emailList
      .split(';')
      .map((email) => email.trim())
      .filter((email) => email.length > 0)
  })

  selectedFileToUpdate$ = signal<UploadedFile | null>(null)
  ifEmailListChanged$ = computed(() => {
    return (
      this.emailShareList$() ==
      this._formatSharedWithPipe.transform(this.selectedFileToUpdate$().shared_with)
    )
  })

  file2Up: File
  isFileOk: boolean = false
  shared: boolean = false
  isUploading: boolean = false // Temporary until api do this async
  uploadedFiles: UploadedFile[] = [] //@todo should be in service and signal

  layers$ = this._layerService.layers$.asReadonly()
  selectedLayer = null
  displayedColumns: string[] = ['filename', 'layer', 'size', 'share', 'actions']

  constructor(
    private _uploadService: UploadService,
    private _layerService: LayersService,
    private _fileManagementComponent: FileManagementComponent,
    private _formatSharedWithPipe: FormatSharedWithPipe,
    private _dialog: MatDialog,
  ) {
    super()
    effect(
      () => {
        if (this.selectedFileToUpdate$() != null) {
          this.emailShareList$.set(
            this._formatSharedWithPipe.transform(this.selectedFileToUpdate$().shared_with),
          )
          // @todo should make a real form
        }
      },
      {
        allowSignalWrites: true,
      },
    )
  }

  handleChildAction(data: UploadedFile) {
    this.selectedFileToUpdate$.set(data)
  }

  cancelLayerUpdate() {
    this.selectedFileToUpdate$.set(null)
    this.emailShareList$.set('')
  }

  ngOnInit() {
    // @todo: check if getFiles is useful with getUploadedFiles just after
    this._uploadService.getUserPersonalAndSharedLayers()

    this._uploadService.uploadedFiles.pipe(takeUntil(this._unsubscribe$)).subscribe((files) => {
      this.uploadedFiles = files //@todo not good
        .map((file) => {
          if (this._uploadService.filterPersonalOrShareLayerWithTypeInProject(file)) {
            return { ...file, layerName: this._getLayerName(file) }
          }
        })
        .filter((layer) => layer) // to removed undefined,
    })
  }

  private _getLayerName(layer: any): string {
    if (layer.layer_type == 'heat2') {
      return 'Heat density total (future)' //@ToDo: Fix this hardcode workaround for heat2
    } else {
      for (let i in this.layers$()) {
        if (this.layers$()[i].workspaceName == layer.layer) {
          return this.layers$()[i].name
        }
      }
      return layer.layer // @ToDo, why is set as workspace when sending and stored in db but here "layer" ?
    }
  }

  getFiles() {
    this._uploadService.getUserPersonalAndSharedLayers()
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      this.file2Up = event.target.files[0]
      this.isFileOk = true
    } else this.isFileOk = false
  }

  fileUpload() {
    if (!(this.isFileOk && this.selectedLayer)) return

    if (this.file2Up.size / 1000 >= 800) {
      //Ask user to confirm when uploading potential large files.
      if (
        !confirm(
          'Your file may cover a large area, and may take up to some minutes to generate. Would you like to continue anyway?',
        )
      )
        return
    }

    if (this.selectedLayer == 'geojson') {
      this.selectedLayer = {
        workspaceName: 'geojson', // use for "Layer type" column in Files modal
      }
    }

    this.isUploading = true
    this._uploadService
      .add(this.file2Up, String(this.shared), this.selectedLayer, this.parsedEmailList())
      .then((success) => {
        if (success) {
          this.isUploading = false
          this._fileManagementComponent.updateDiskSpace()
          this.file2Up = null
          this.isFileOk = false
          this.selectedLayer = null
          this.inputFile2.nativeElement.value = ''
          this.inputFile.nativeElement.value = ''
        } else this.isFileOk = true
        this.isUploading = false
      })
  }

  async updateListShare() {
    this.isUploading = true
    const currentLayerID = this.selectedFileToUpdate$().id
    await this._uploadService.update(currentLayerID, this.parsedEmailList())
    await this._uploadService.getUserPersonalAndSharedLayers()
    const updatedLayer = this.uploadedFiles.find((file) => file.id == currentLayerID)
    this.selectedFileToUpdate$.set(updatedLayer)
    this.isUploading = false
  }

  download(upFile: UploadedFile) {
    this._uploadService.download(upFile).then((url) => {
      if (url === '') return
      // window.open(url); //POPUP blocker
      const a = document.createElement('a')
      a.href = url
      a.download = upFile.name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      URL.revokeObjectURL(url)
    })
  }

  openConfirmationDialog(id): void {
    const dialogRef = this._dialog.open(LayerDeleteConfirmPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: '400px',
    })

    dialogRef.afterClosed().subscribe((data) => {
      if (data) this._delete(id)
    })
  }

  private _delete(id: number | UploadedFile) {
    this._uploadService.delete(id)
    this.selectedFileToUpdate$.set(null)
    this.emailShareList$.set('')
    this._fileManagementComponent.updateDiskSpace()
  }
}
