import { Injectable, signal } from '@angular/core'
import { UploadedFile } from '@core/models/upload.class'
import { UploadService } from '@core/services/upload.service'

export interface UploadedLayer extends UploadedFile {
  checked?: boolean
}

@Injectable()
export class UploadedLayersService {
  personalLayers$ = signal<UploadedLayer[]>([])
  shareLayers$ = signal<UploadedLayer[]>([])

  constructor(private _uploadService: UploadService) {
    if (this._uploadService.uploadedFiles) {
      this._uploadService.uploadedFiles.subscribe((files) => {
        // Only personal layer with a type already in layer list
        this.personalLayers$.set(
          files
            .filter((file) => this._uploadService.filterPersonalOrShareLayerWithTypeInProject(file))
            .map((file) => {
              // To keep selected layers as selected
              this.personalLayers$().forEach((layer) => {
                if (layer.id === file.id) (file as UploadedLayer).checked = layer.checked
              })
              return file
            }),
        )
      })
    }

    if (this._uploadService.shareFiles) {
      this._uploadService.shareFiles.subscribe((files) => {
        // Only share layer with a type already in layer list
        this.shareLayers$.set(
          files
            .filter((file) => this._uploadService.filterPersonalOrShareLayerWithTypeInProject(file))
            .map((file) => {
              // To keep selected layers as selected
              this.shareLayers$().forEach((layer) => {
                if (layer.id === file.id) (file as UploadedLayer).checked = layer.checked
              })
              return file
            }),
        )
      })
    }
  }

  actionLayer(source: 'personal' | 'shared', layer: UploadedLayer) {
    if (layer.checked) {
      this._uploadService.remove(layer)
      layer.checked = false
    } else {
      this._uploadService.show(layer)
      layer.checked = true
    }

    // Synchronize share layers checkbox (if you share your layer to your email)
    if (source === 'personal') {
      const shareLayerToToggle = this.shareLayers$().find((sharelayer) => sharelayer.id == layer.id)
      if (shareLayerToToggle) shareLayerToToggle.checked = layer.checked
    } else if (source === 'shared') {
      const persoLayerToToggle = this.personalLayers$().find(
        (sharelayer) => sharelayer.id == layer.id,
      )
      if (persoLayerToToggle) persoLayerToToggle.checked = layer.checked
    }
  }

  unCheckAllUploadedLayers() {
    this._uploadService.clearLayerSelection()
    this.personalLayers$().forEach((layer) => {
      layer.checked = false
      this._uploadService.remove(layer)
    })
    this.shareLayers$().forEach((layer) => {
      layer.checked = false
      this._uploadService.remove(layer)
    })
  }
}
