import { environmentCitiwattsBase } from '@env/environment.ctwa'

export const environmentCitiwattsDev = {
  ...environmentCitiwattsBase,
  apiUrl: 'https://api.citiwattsdev.hevs.ch/api',
  geoserverUrl: 'https://geoserver.citiwattsdev.hevs.ch/geoserver/hotmaps/wms',
  keycloakUrl: 'https://auth.citiwattsdev.hevs.ch',
  keycloakRealm: 'citiwatts-dev',
  matomoTrackerUrl: 'https://analytics.citiwattsdev.hevs.ch/',
  matomoSiteId: '1',
  maintenanceNotice: '2025-01-20 - 2025-01-22', // Set to '' if no maintenance is scheduled or the dates of the maintenance eg. '2025-01-20 - 2025-01-21' FORMAT IS IMPORTANT, end date is included
}

export const environment = environmentCitiwattsDev
