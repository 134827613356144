import { Component, effect, Input, OnChanges, SimpleChanges } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CalculationModuleService } from '@components/_panel-left/cms-tab/calculation-modules/calculation-module.service'
import { LayerInteractionService } from '@components/_panel-left/layers-tab/layers-interaction/layers-interaction.service'
import { ChartComponent } from '@components/chart/chart.component'
import { CALCULATION_MODULE_CATEGORY } from '@core/constants/constant.data'
import { RASTER_TYPE, VECTOR_TYPE } from '@core/constants/layers.data'
import { ScaleLevel } from '@core/models/scale-level.class'
import { InteractionService } from '@core/services/interaction.service'
import { SelectionScaleService } from '@core/services/selection-scale.service'
import { ToasterService } from '@core/services/toaster.service'
import { MapService } from '@pages/map/services/map.service'
import { isNullOrUndefinedString } from '@services/core.utilities'
import { ElectricityMixComponent } from './electricity-mix/electricity-mix.component'
import { ENERGY_MIX_OPTIONS } from './electricity-mix/electricity-mix.data'
import { ElectricityMixService } from './electricity-mix/electricity-mix.service'
import { ExportDataComponent } from './export-data/export-data.component'
import { ResultManagerPayload, ScenarioResults } from './result-manager'
import { DEFAULT_DROP_DOWN_BUTTON, DROP_DOWN_RESULT_LIST } from './result-manager.data'
import { SummaryResultComponent } from './summary-result/summary-result.component'
import { SummaryResultService } from './summary-result/summary-result.service'

@Component({
  standalone: true,
  selector: 'app-result-manager',
  templateUrl: 'result-manager.component.html',
  styleUrls: ['result-manager.component.css'],
  imports: [
    FormsModule,
    ReactiveFormsModule,

    // Components
    ChartComponent,
    ExportDataComponent,
    SummaryResultComponent,
    ElectricityMixComponent,
  ],
})
export class ResultManagerComponent implements OnChanges {
  @Input() cmPayload
  @Input() summaryPayload: any
  @Input() energyMixPayload
  @Input() personalLayerPayload
  @Input() scenarioExplorerData: ScenarioResults

  scaleLevel: ScaleLevel = this._selectionScaleService.currentScaleLevel$()

  graphicsExportButtonState = false
  indicatorExportButtonState = false
  indicatorLoading = false
  indicatorPersoLoading = false
  noIndicator = true
  tab1 = 'indicator'
  tab2 = 'charts'
  cm_category = CALCULATION_MODULE_CATEGORY
  tabSelected = this.tab1
  dropdown_btns = DROP_DOWN_RESULT_LIST
  selectedButton = this.dropdown_btns[0]
  categoryDisplayed = 0
  result: ResultManagerPayload = {
    indicators: {
      summaryResult: null,
      personalLayerResult: null,
      cmResult: null,
    },
    graphics: null,
    raster_layers: null,
    vector_layers: null,
  }

  private _animationTimeout
  private _status_id
  private _progressCmAnimation = 0

  constructor(
    private _interactionService: InteractionService,
    private _layerInteractionService: LayerInteractionService,
    private _summaryResultService: SummaryResultService,
    private _selectionScaleService: SelectionScaleService,
    private _mapService: MapService,
    private _toasterService: ToasterService,
    private _electricityMixService: ElectricityMixService,
    private _calculationModuleService: CalculationModuleService,
  ) {
    effect(() => {
      this.scaleLevel = this._selectionScaleService.currentScaleLevel$()
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    this._resetResult()
    if (!isNullOrUndefinedString(this.cmPayload) && !isNullOrUndefinedString(changes.cmPayload)) {
      this._updateCMResult()
    }
    if (!isNullOrUndefinedString(this.summaryPayload)) {
      this._updateSummaryResult()
    }
    if (
      !isNullOrUndefinedString(this.energyMixPayload) &&
      this.summaryPayload.layers.includes('yearly_co2_emission')
      /*&&
      (JSON.stringify(changes.energyMixPayload.currentValue) !== JSON.stringify(changes.energyMixPayload.previousValue))*/
    ) {
      this._updateEnergyMixResult()
    }
    if (!isNullOrUndefinedString(this.personalLayerPayload)) {
      this._updatePersonalLayersResult()
    }
    if (!isNullOrUndefinedString(this.scenarioExplorerData)) {
      this._updateScenarioResults()
    }
  }

  tabSwitch(tabName) {
    this.tabSelected = tabName
  }

  changeResultsDisplay(button) {
    this.selectedButton = button
  }

  private _updateCMResult() {
    if (!isNullOrUndefinedString(this._status_id)) {
      this._calculationModuleService.deleteCM(this._status_id)
    }
    this._calculationModuleService
      .getCMInformation(this.cmPayload)
      .then((data) => {
        this._status_id = data.status_id
        this._interactionService.currentCMiD = this._status_id
        this._getStatusOfCM()
      })
      .catch((err) => {
        this._stopAnimation()
        console.warn('Error fetching CM info', err)
      })
  }

  private _updatePersonalLayersResult() {
    if (isNullOrUndefinedString(this.personalLayerPayload)) {
      return
    }
    this.indicatorPersoLoading = true
    this._summaryResultService
      .getSummaryResultPersonalLayers(this.personalLayerPayload)
      .then((result) => {
        this._setSummaryResult(result, 'personalLayerResult')
        this._getIndicatorsCategories()
        this.indicatorPersoLoading = false
      })
      .catch((e) => {
        console.warn('updatePersonalLayersResult', e)
        const result = {
          layers: [],
          no_data_layers: [],
        }

        for (const layer of this.personalLayerPayload.layers) {
          result.no_data_layers.push(layer.layer_name)
        }

        this._setSummaryResult(result, 'personalLayerResult')
        this._getIndicatorsCategories()
        this.indicatorPersoLoading = false

        this._toasterService.showDangerToaster(
          `No data found for \n${result.no_data_layers.map((item) => item + '\n')}`,
        )
      })
  }

  private _killAnimation() {
    this._progressCmAnimation = 0
    this._calculationModuleService.cmAnimationStatus.next(this._progressCmAnimation)
    this._interactionService.cmRunningProgress = this._progressCmAnimation
  }

  private _updateSummaryResult() {
    this.indicatorLoading = true

    if (this.scaleLevel.id === 5) {
      this._summaryResultService
        .getSummaryResultWithMultiAreas(this.summaryPayload)
        .then((result) => {
          this._setSummaryResult(result, 'summaryResult')
          this._getIndicatorsCategories()
          this.indicatorLoading = false
        })
        .catch((e) => {
          console.error(e)
          this.indicatorExportButtonState = false
          this.indicatorLoading = false
          console.warn('updateSummaryResult error, scale 5', e)
        })
    } else {
      this._summaryResultService
        .getSummaryResultWithIds(this.summaryPayload)
        .then((result) => {
          this._setSummaryResult(result, 'summaryResult')
          this._getIndicatorsCategories()
          this.indicatorLoading = false
        })
        .catch((e) => {
          this._resetIndicators()
          this.indicatorExportButtonState = false
          this.indicatorLoading = false

          console.warn('updateSummaryResult error, not scale 5', e)
        })
    }
  }

  private _setSummaryResult(result, type) {
    this.result.indicators[type] = result
    this.indicatorExportButtonState = true
  }

  private _updateEnergyMixResult() {
    const graphic = this._addGraphic(
      'Electricity generation mix',
      'pie',
      [],
      [],
      ENERGY_MIX_OPTIONS,
      'energy_mix',
      true,
    )

    this._electricityMixService
      .getElectricityMix(this.energyMixPayload)
      .then((result) => {
        graphic.isLoading = false
        graphic.data = result.datasets
        graphic.labels = result.labels
        this.graphicsExportButtonState = true
      })
      .catch((e) => {
        this.graphicsExportButtonState = false
        graphic.isLoading = false
        this._toasterService.showToaster(
          'Please, select at least one NUTS 0 area to display the Electricity Mix chart!',
        )
        console.warn('updateEnergyMixResult error', e)
      })
  }

  /**
   * Updates the scenario results
   */
  private _updateScenarioResults() {
    // Populate values in results
    let valuesLayers = []
    let totalPop = 0
    this.scenarioExplorerData.layers.forEach((e) => {
      totalPop = totalPop + e.properties.value
      valuesLayers.push({
        // e.properties.country also contains the region value
        // e.properties.region is not always defined (when selecting "country" as region scale)
        name: `${e.properties.variable} (${e.properties.country})`,
        value: e.properties.value,
        unit: e.properties.unit,
      })
    })
    if (this.scenarioExplorerData.layers.length > 1) {
      let sum = {
        name: `TOTAL ` + this.scenarioExplorerData.scenarioParameters.variable,
        value: totalPop,
        unit: this.scenarioExplorerData.layers[0].properties.unit,
      }
      valuesLayers.unshift(sum)
    }

    let results = {
      layers: [
        {
          name: `${this.scenarioExplorerData.scenarioParameters.model} - ${this.scenarioExplorerData.scenarioParameters.year}`,
          values: valuesLayers,
          category: [DEFAULT_DROP_DOWN_BUTTON],
        },
      ],
      no_data_layers: this.scenarioExplorerData.no_data_layers,
      scenarioParameters: this.scenarioExplorerData.scenarioParameters,
    }

    this._setSummaryResult(results, 'scenarioResult')
  }

  private _processAndShowCmResult(data) {
    const response = data
    if (response['state'] === 'SUCCESS') {
      this._stopAnimation()

      let name_of_result = ''
      if (!isNullOrUndefinedString(this.cmPayload)) {
        name_of_result = this.cmPayload.cm_name
      }

      let prefix_name = ''
      if (this.cmPayload.cm_prefix != '') {
        prefix_name = this.cmPayload.cm_prefix + ' - '
      }

      if (!isNullOrUndefinedString(response.status.result.raster_layers)) {
        response.status.result.raster_layers.map((raster) => {
          let symb
          if (raster.type === 'custom') {
            symb = raster.symbology
          }

          this._layerInteractionService.addNewCMLayer(
            prefix_name + raster.name,
            raster.layer,
            raster.path,
            raster.type,
            RASTER_TYPE,
            symb,
          )
          this._mapService.displayCustomLayerFromCM(raster.path, RASTER_TYPE)
        })
      }

      if (!isNullOrUndefinedString(response.status.result.vector_layers)) {
        response.status.result.vector_layers.map((vector) => {
          let symb
          if (vector.type == 'custom') {
            symb = vector.symbology
          }
          this._layerInteractionService.addNewCMLayer(
            prefix_name + vector.name,
            vector.layer,
            vector.path,
            vector.type,
            VECTOR_TYPE,
            symb,
          )

          this._mapService.displayCustomLayerFromCM(vector.path, VECTOR_TYPE)
        })
      }

      if (
        !isNullOrUndefinedString(response.status.result.indicator) &&
        response.status.result.indicator.length >= 1
      ) {
        this.result.indicators.cmResult = {
          layers: [
            {
              name: name_of_result,
              values: response.status.result.indicator,
              category: [DEFAULT_DROP_DOWN_BUTTON, CALCULATION_MODULE_CATEGORY],
            },
          ],
        }
        this.indicatorExportButtonState = true
      }

      if (response.status.result.csv_files) {
        this.result.indicators.cmResult.csv_files = response.status.result.csv_files
      }
      if (response.status.result.extra_zip_files) {
        this.result.indicators.cmResult.extra_zip_files = response.status.result.extra_zip_files
      }

      this.indicatorLoading = false
      if (
        !isNullOrUndefinedString(response.status.result.graphics) &&
        response.status.result.graphics.length >= 1
      ) {
        response.status.result.graphics.map((graphic) => {
          const option_calculation_module = {
            pointStyle: false,
            scales: {
              y: {
                title: { display: true, text: graphic.yLabel },
                min: 0,
              },

              x: {
                title: { display: true, text: graphic.xLabel },
                ticks: { autoSkip: false },
                min: 0,
              },
            },
          }
          if (graphic.title) {
            name_of_result = graphic.title + ' (' + name_of_result + ')'
          }
          this._addGraphic(
            name_of_result,
            graphic.type,
            graphic.data.datasets,
            graphic.data.labels,
            option_calculation_module,
            CALCULATION_MODULE_CATEGORY,
            false,
          )
        })
        this.graphicsExportButtonState = true
      }
      this._getIndicatorsCategories()
    } else {
      this._animationTimeout = setTimeout(() => {
        this._getStatusOfCM()
        this._runAnimation()
      }, 1000)
    }
  }

  private _getStatusOfCM() {
    this.indicatorLoading = true

    if (this._interactionService.currentCMiD != null) {
      this._calculationModuleService
        .getStatusOfCM(this._interactionService.currentCMiD)
        .then((data) => {
          this._processAndShowCmResult(data)
        })
        .catch((err) => {
          this._animationTimeout = setTimeout(() => {
            this._stopAnimation()
          }, 1000)
          this.indicatorLoading = false

          this._calculationModuleService.cmAnimationStatus.next(null)
          console.warn('getStatusOfCM error', err)
        })
    } else {
      console.warn('getStatusOfCM', 'everything should have stop')
      this._animationTimeout = setTimeout(() => {
        this._stopAnimation()
      }, 1000)
      this.indicatorLoading = false
      this._calculationModuleService.cmAnimationStatus.next(null)
    }
  }

  // useless ?
  private _runAnimation() {
    if (this._progressCmAnimation === 100) {
      this._progressCmAnimation = 10
    } else {
      this._progressCmAnimation += 10
    }
    this._calculationModuleService.cmAnimationStatus.next(this._progressCmAnimation)
    this._interactionService.cmRunningProgress = this._progressCmAnimation
  }

  private _stopAnimation() {
    if (!isNullOrUndefinedString(this._animationTimeout)) {
      clearTimeout(this._animationTimeout)
    }
    if (!isNullOrUndefinedString(this._status_id)) {
      this._calculationModuleService.deleteCM(this._status_id)
    }
    this._killAnimation()
    this._calculationModuleService.cmRunning.next(false)
  }

  private _getIndicatorsCategories() {
    this._resetButtonsDisplay()
    for (let key of Object.keys(this.result.indicators)) {
      if (isNullOrUndefinedString(this.result.indicators[key])) {
        continue
      }
      const lay = this.result.indicators[key].layers
      if (lay == null || lay.length === 0) {
        this.noIndicator = true
      } else {
        lay.map((layer) => {
          if (!isNullOrUndefinedString(layer.name)) {
            let refToDisplay = []
            if (isNullOrUndefinedString(layer.category)) {
              refToDisplay = this._layerInteractionService.getRefFromLayerName(layer.name)
              layer.category = refToDisplay
            } else {
              refToDisplay = layer.category
            }

            refToDisplay.map((ref) => {
              this.dropdown_btns.filter((x) => x.ref === ref)[0].display = true
            })
          }
        })
        this.noIndicator = false
      }
    }
    this.selectedButton = this.dropdown_btns[0]
    this.selectedButton.selected = true
    this.categoryDisplayed = this.dropdown_btns.filter((x) => x.display === true).length
  }

  private _addGraphic(name, type, data, labels, options, category, isLoading) {
    const graphic = {
      name: name,
      type: type,
      data: data,
      labels: labels,
      options: options,
      category: category,
      isLoading: isLoading,
    }
    this.result.graphics.push(graphic)
    return graphic
  }

  private _resetButtonsDisplay() {
    this.dropdown_btns.map((btn) => {
      btn.display = false
    })
  }

  private _resetResult() {
    this.graphicsExportButtonState = false
    this.indicatorExportButtonState = false

    this._resetIndicators()
    this.result.graphics = []
    this.result.raster_layers = []
    this.result.vector_layers = []
  }

  private _resetIndicators() {
    // This function unsets the CM/personal layers results which are in the right-side-panel
    this.indicatorExportButtonState = false
    this.result.indicators = {
      personalLayerResult: null,
      summaryResult: null,
      cmResult: null,
    }
  }
}
