@if (tabSelected === 'indicator' && hasIndicatorsDataToExport()) {
  <div style="padding: 10px">
    <button
      id="exportBtnIndicator"
      class="uk-button uk-button-secondary"
      [disabled]="!indicatorState"
      (click)="exportIndicators()"
    >
      Export indicators
    </button>

    @if (hasExtraFilesToExport()) {
      <button
        id="exportBtnIndicator"
        class="uk-button uk-button-secondary"
        (click)="exportExtraOutputFilesCM()"
      >
        Export extra files
      </button>
    }
  </div>
}

@if (tabSelected === 'charts') {
  <div style="padding: 10px">
    <button
      id="exportBtnGraph"
      class="uk-button uk-button-secondary"
      [disabled]="!graphState"
      (click)="exportGraphic()"
    >
      Export charts
    </button>
  </div>
}
