import { animate, state, style, transition, trigger } from '@angular/animations'
import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { CALCULATION_MODULE_CATEGORY, LAYERS_ORDER } from '@core/constants/constant.data'
import { InteractionService } from '@core/services/interaction.service'
import { MapService } from '@pages/map/services/map.service'
import { MatomoTracker } from 'ngx-matomo-client'
import { LayerClass } from '../layers-interaction.class'
import { LayerToolComponent } from './layer-tool/layer-tool.component'
@Component({
  standalone: true,
  selector: 'app-layers-interaction-cell',
  templateUrl: 'layers-interaction-cell.component.html',
  styleUrls: ['layers-interaction-cell.component.css'],
  animations: [
    // Define an animation that adjusts the opactiy when a new item is created
    //  in the DOM. We use the 'visible' string as the hard-coded value in the
    //  trigger.
    //
    // When an item is added we wait for 300ms, and then increase the opacity to 1
    //  over a 200ms time interval. When the item is removed we don't delay anything
    //  and use a 200ms interval.
    //
    trigger('visibleTrigger', [
      state('visible', style({ opacity: '1' })),
      transition('void => *', [style({ opacity: '0' }), animate('200ms 300ms')]),
      transition('* => void', [animate('200ms', style({ opacity: '0' }))]),
    ]),
  ],
  imports: [
    CommonModule,

    // Components
    LayerToolComponent,
  ],
})
export class LayerCellComponent {
  @Input({ required: true }) layer: LayerClass
  @Input() expanded: boolean

  constructor(
    private _mapService: MapService,
    public interactionService: InteractionService,
    private readonly _tracker: MatomoTracker,
  ) {}

  //@TODO make this declarative
  showRemoveLayer(layerWorkspaceName) {
    if (this.interactionService.summaryResultState === true) {
      return
    }
    if (!this.layer.isSelected) {
      this._tracker.trackEvent('Layer', 'Layer Selected', layerWorkspaceName)
    }

    this.layer.isSelected = !this.layer.isSelected

    if (this.layer.category === CALCULATION_MODULE_CATEGORY) {
      this._mapService.displayCustomLayerFromCM(this.layer.cm_id, this.layer.type_of_layer)
    } else {
      let order = LAYERS_ORDER
      if (this.layer.order) {
        order = this.layer.order
      }
      this._mapService.showOrRemoveLayer(this.layer.workspaceName, order)
      this._mapService.setLayersSubject()
    }
  }
}
