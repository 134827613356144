@if (isLoggedIn) {
  <div>
    <div class="modal-fileManagement">
      <div mat-dialog-title class="dialog-title">
        <h2>Welcome, {{ firstname }}</h2>

        <button
          mat-icon-button
          class="modal-close-button"
          (click)="closeModal()"
          matTooltip="Download"
        >
          <mat-icon fontIcon="close"></mat-icon>
        </button>
      </div>

      <div class="uk-child-width-2-2@m uk-grid-match" uk-grid>
        <h3>User disk space ({{ ((diskSpaceUsed / diskSpaceMax) * 100).toFixed(2) }} % used)</h3>
        <div class="chart-disk-space">
          <app-chart
            type="bar"
            [labels]="diskSpaceLabels"
            [options]="diskSpaceOptions"
            [datasets]="diskSpaceDataset"
            [isLoading]="false"
          ></app-chart>
        </div>
      </div>
      @if (localhost) {
        <p class="errorMsgPersonalLayer">
          Personal layers are linked to the connected API at the time of upload. You will not be
          able to load or delete data uploaded locally (when using docker-compose-local.yml) on
          citiwattsdev or vice versa. Send an email citiwatts&#64;hevs.ch to for more information.
        </p>
      }

      <div class="uk-child-width-2-2@m uk-grid-match" uk-grid>
        <app-upload></app-upload>
      </div>
    </div>
  </div>
}
