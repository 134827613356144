import { Injectable, Pipe, PipeTransform } from '@angular/core'
import { SharedWithKeyValue } from '@core/models/upload.class'

@Injectable({
  providedIn: 'root',
})
@Pipe({
  standalone: true,
  name: 'formatSharedWith',
})
export class FormatSharedWithPipe implements PipeTransform {
  transform(sharedWith: SharedWithKeyValue[]): string {
    if (sharedWith && sharedWith.length > 0) {
      const userSubs = sharedWith.map((item) => item.email).join('; ')
      return userSubs
    }
    return ''
  }
}
