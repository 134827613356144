<h2 mat-dialog-title class="dialog-title">Cookies</h2>

<!-- ! EACH TIME YOU CHANGE THIS TEXT, YOU HAVE TO INCREMENT cookiesVersion IN map.component.ts, the message will appear again to all user -->
<div mat-dialog-content>
  <p>
    The {{ appName }} toolbox uses mandatory operational cookies for identification and access to
    geographical data. By using the toolbox, you agree to the use of these cookies.
  </p>
  <p>
    We use the Matomo web analytics application to report performance and usage of the platform to
    its funding bodies. This tool gathers the following information about the device you use to
    access the
    {{ appName }} website: Operating system, Browser, Browser language, Browser plugins, Your public
    IP address.
  </p>
</div>

<div mat-dialog-actions align="end">
  <button mat-flat-button color="warn" (click)="handleDeclineStatisticsCookies()">
    Decline statistics cookies
  </button>

  <button mat-flat-button color="success" (click)="handleAcceptAllCookies()">
    Accept all cookies
  </button>
</div>
