import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from '@core/services/auth.service'
import { ToasterService } from '@services/toaster.service'
import { MatomoTracker } from 'ngx-matomo-client'
import { GdprComponent } from './gdpr/gdpr.component'

@Component({
  standalone: true,
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css'],
  imports: [
    // Components
    GdprComponent,
  ],
})
export class UserManagementComponent implements OnInit {
  isLoggedIn = false
  email: string
  name: string
  initial: string

  constructor(
    private _authService: AuthService,
    private _toasterService: ToasterService,
    private _router: Router,
    private readonly _tracker: MatomoTracker,
  ) {}

  async ngOnInit() {
    this.isLoggedIn = await this._authService.isLoggedIn()
    // If logged in, get the username (keycloak version)
    if (this.isLoggedIn) {
      this.email = this._authService.getUserEmail()
      this.name = this._authService.getFirstname() + ' ' + this._authService.getLastname()
      this.initial =
        this._authService.getFirstname().substring(0, 1) +
        this._authService.getLastname().substring(0, 1)
    }

    // Check if loggedOut or loggedIn query parameter is present and show toaster
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('loggedOut')) {
      this._toasterService.showToaster('You have successfully logged out.')
    } else if (urlParams.has('loggedIn')) {
      this._toasterService.showToaster(`User connected. Welcome ${this.name}`)
    }
    //Remove the query parameter from the URL without refreshing the page
    this._router.navigate([], {
      queryParams: { loggedOut: null, loggedIn: null },
      queryParamsHandling: 'merge',
    })
  }

  // Method to handle login button click
  login() {
    this._tracker.trackEvent('Login', 'Login Button Click', 'user try to login or register')
    this._authService.login()
  }

  // Method to handle logout
  logout() {
    this._authService.logout()
  }

  // logic should be in service? (18.11.24)
  editAccount() {
    const keycloakBaseUrl = this._authService.getKeycloakInstance().authServerUrl
    const realmName = this._authService.getKeycloakInstance().realm

    // Construct the URL for the Keycloak Account Management page
    const accountUrl = `${keycloakBaseUrl}/realms/${realmName}/account`

    // Redirect the user to the Keycloak Account Management page
    window.location.href = accountUrl
  }
}
