<div class="containers" [@containerTrigger]>
  <h4>Selection tool</h4>
  <div class="selection-tools-box">
    <div class="selection-tools-radios">
      @for (button of stButtons; track $index) {
        <div>
          <input
            id="radio-{{ button.id }}"
            class="radio-custom"
            name="radio-group"
            type="radio"
            [checked]="button.isChecked"
            (click)="drawTool(button)"
          />
          <label
            [@elementsTrigger]="'in'"
            id="{{ button.type }}"
            for="radio-{{ button.id }}"
            class="radio-custom-label"
          ></label>
        </div>
      }
    </div>

    <!-- @ToDo redo all css -->
    <div class="form-group">
      <label
        for="fileInput"
        class="upload-button upload"
        [class.upload-disabled]="!isHectareSelected"
        matTooltip="Upload GeoJSON selection (Hectare only)"
      >
        <input
          type="file"
          id="fileInput"
          (change)="uploadShapes($event)"
          [disabled]="!isHectareSelected"
          class="file-upload"
          accept=".geojson"
      /></label>
    </div>
  </div>

  <div class="info-box">
    <div class="uk-container uk-padding-remove">
      <div>
        <p [@elementsTrigger]="'in'">
          {{ elementSelected }}{{ selectionToolService.nbOfElementsSelected$() > 1 ? 's' : ''
          }}<span>{{ selectionToolService.nbOfElementsSelected$() }}</span>
        </p>
        <p
          [class.color-red]="selectionToolService.selectedSurface$() > maxSurfaceValueCM"
          [@elementsTrigger]="'in'"
        >
          Surface area<span
            >{{ selectionToolService.selectedSurface$() | number: '1.0-2' | numberformat }} km<sup
              >2</sup
            ></span
          >
        </p>
        <p [@elementsTrigger]="'in'">
          Scale<span>{{ this.selectionScaleService.currentScaleLevel$().displayName }}</span>
        </p>
      </div>
      <div class="divButtons">
        <button
          id="loadBtn"
          class="uk-button uk-button-primary uk-button-small"
          [disabled]="isLoadBtnDisabled"
          [@elementsTrigger]="'in'"
          (click)="loadResultsButton()"
          style="margin-right: 2%"
        >
          Load results
        </button>
        <button
          id="clearBtn"
          class="uk-button uk-button-danger uk-button-small"
          [disabled]="isClearBtnDisabled$()"
          [@elementsTrigger]="'in'"
          (click)="clearLayers()"
        >
          @if (selectionToolService.nbOfDrawnElementSelected$() == 0) {
            <span
              >Clear
              {{ selectionToolService.nbOfElementsSelected$() }}
              area{{ selectionToolService.nbOfElementsSelected$() > 1 ? 's' : '' }}</span
            >
          } @else {
            <span
              >Clear
              {{ selectionToolService.nbOfDrawnElementSelected$() }}
              area{{ selectionToolService.nbOfDrawnElementSelected$() > 1 ? 's' : '' }}</span
            >
          }
        </button>
      </div>
    </div>
  </div>
</div>
