<ul uk-accordion>
  <li class="uk-open">
    <h3 class="uk-accordion-title">Shared layer</h3>
    <div class="uk-accordion-content" style="position: relative; width: 100%">
      @for (layer of uploadedLayersService.shareLayers$(); track $index) {
        <div class="layers" (click)="uploadedLayersService.actionLayer('shared', layer)">
          <button
            type="button"
            class="uk-button uk-button-default uk-width-1-1"
            [class.button-secondary]="layer.checked"
            [matTooltip]="'Shared by ' + layer.owner_email"
            matTooltipPosition="below"
          >
            <input class="uk-checkbox" type="checkbox" [checked]="layer.checked" /><span>{{
              layer.name
            }}</span>
          </button>
        </div>
      }
    </div>
  </li>
</ul>
