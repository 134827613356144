import { Component, inject, OnInit } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogRef } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'

import { ChartComponent } from '@components/chart/chart.component'
import { AuthService } from '@core/services/auth.service'
import { ChartOptions } from 'chart.js'
import { environment } from 'environments/environment'
import { UserManagementService } from '../../user-management/user-management.service'
import { UploadComponent } from '../upload/upload.component'

@Component({
  standalone: true,
  selector: 'app-file-management',
  templateUrl: './file-management.component.html',
  styleUrls: ['./file-management.component.css'],
  providers: [UserManagementService],
  imports: [
    // Components
    ChartComponent,
    UploadComponent,

    // Material
    MatIconModule,
    MatButtonModule,
  ],
})
export class FileManagementComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<FileManagementComponent>)
  isLoggedIn = false

  firstname = ''
  lastname = ''

  diskSpaceUsed = 0
  diskSpaceMax = 0
  diskSpaceLabels = ['']
  diskSpaceDataset
  diskSpaceOptions: ChartOptions = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    maintainAspectRatio: false,
    indexAxis: 'y',
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            return (
              context.dataset.label +
              ': ' +
              context.dataset.data[context.dataIndex].toString().substring(0, 6) +
              ' MB'
            )
          },
        },
      },
    },
  }

  localhost: boolean = environment.apiUrl.includes('localhost')

  constructor(
    private _userManagementService: UserManagementService,
    private _authService: AuthService,
  ) {}

  ngOnInit() {
    this._fetchInformation()
  }

  closeModal(): void {
    this.dialogRef.close()
  }

  openFiles() {
    this._fetchInformation()
  }

  getAccountInformation() {
    this._getUserInformation()
    this._userManagementService.getDiskSpace().then((data) => {
      this.diskSpaceMax = data.max_size
      this.diskSpaceUsed = data.used_size
      this.diskSpaceDataset = [
        {
          label: 'Account disk',
          backgroundColor: ['#bf2630', '#26bf3d'],
          data: [this.diskSpaceUsed, this.diskSpaceMax - this.diskSpaceUsed],
        },
      ]
    })
  }

  updateDiskSpace() {
    this.getAccountInformation()
  }

  private async _fetchInformation() {
    this.isLoggedIn = await this._authService.isLoggedIn()

    if (this.isLoggedIn) {
      this.getAccountInformation()
    }
  }

  private _getUserInformation() {
    this.firstname = this._authService.getFirstname()
    this.lastname = this._authService.getLastname()
  }
}
