/* ******************************************************************
 * * Copyright         : 2024 HES-SO Valais-Wallis - Institute of Informatics - EASILab
 * * Description       :
 * * Revision History  :
 * * Date           Author                              Comments
 * * ---------------------------------------------------------------------------
 * * 20.10.2020     Albain Dufils - CREM               Creation
 * * 20.10.2020     Daniel Hunacek - HES-SO              Creation
 * *
 ******************************************************************/

import { Component } from '@angular/core'
import { MatTooltipModule } from '@angular/material/tooltip'
import { UploadedLayersService } from '../uploaded-layers.service'

@Component({
  standalone: true,
  selector: 'app-share-layers',
  templateUrl: './share-layers.component.html',
  styleUrls: ['./share-layers.component.css'],
  imports: [MatTooltipModule],
})
export class ShareLayersComponent {
  constructor(public uploadedLayersService: UploadedLayersService) {}
}
