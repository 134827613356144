@if (standaloneCMs.length > 0) {
  <ul uk-accordion>
    <li class="uk-open">
      <h3 class="uk-accordion-title">Stand-alone CMs</h3>
      <div class="uk-accordion-content">
        @for (cm of standaloneCMs; track $index) {
          <div class="uk-container">
            <div class="uk-offcanvas-content">
              <button
                class="uk-button uk-button-default uk-margin-small-right"
                style="cursor: auto"
              >
                <!-- ToDo: matomo -->
                <span class="cm-name">{{ cm.name }}</span>
                <div class="cm-toolbox">
                  @if (cm.wiki_url) {
                    <a href="{{ cm.wiki_url }}" target="_blank" matTooltip="Wiki URL">
                      <div src="/assets/icons/logo_wiki.svg" class="icon-wiki"></div>
                    </a>
                  }
                  @if (cm.repository_url) {
                    <a
                      href="{{ cm.repository_url }}"
                      target="_blank"
                      class="uk-icon-link git-link"
                      uk-icon="icon:github; ratio: 1.5"
                      matTooltip="Repository URL"
                    ></a>
                  }
                </div>
              </button>
            </div>
          </div>
        }
      </div>
    </li>
  </ul>
}
