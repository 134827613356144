<table class="uk-table uk-table-divider uk-table-small">
  <thead>
    <tr class="header-energy-mix">
      <th class="uk-table-shrink">Data on NUTS0</th>
    </tr>
  </thead>
</table>
<div class="uk-card uk-card-body htm-card">
  <div class="title">
    <h5 class="uk-card-title" style="float: left; margin-bottom: 0px">
      Electricity generation mix
    </h5>
  </div>
  @if (datasets.length != 0) {
    <app-chart
      [datasets]="datasets"
      [labels]="labels"
      [options]="options"
      [type]="type"
      [isLoading]="isLoading"
    ></app-chart>
  } @else {
    <div class="graph-error-msg uk-text-center">
      <span uk-icon="icon: warning"></span> Please, choose at least one area to display the chart!
    </div>
  }
</div>
