/* ******************************************************************
 * * Copyright         : 2024 HES-SO Valais-Wallis - Institute of Informatics - EASILab
 * * Description       :
 * * Revision History  :
 * * Date           Author                              Comments
 * * ---------------------------------------------------------------------------
 * * 31.05.2017     Lesly Houndole - CREM               Creation
 * *
 ******************************************************************/

import { Component, OnInit } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatTooltipModule } from '@angular/material/tooltip'
import { GeocodingService } from '@components/_header-bar/searchbar/geocoding.service'
import { LoaderService } from '@core/services/loader.service'
import { MapService } from '@pages/map/services/map.service'
import * as L from 'leaflet'
import { OpenStreetMapProvider } from 'leaflet-geosearch'
import { MatomoTracker } from 'ngx-matomo-client'

@Component({
  standalone: true,
  selector: 'app-search-bar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss'],
  providers: [GeocodingService],
  imports: [
    // Forms
    FormsModule,
    ReactiveFormsModule,

    // Material
    MatTooltipModule,
  ],
})
export class SearchBarComponent implements OnInit {
  address: string
  results = []

  private _map: L.Map

  constructor(
    private _geocoder: GeocodingService,
    private _mapService: MapService,
    private _loaderService: LoaderService,
    private readonly _tracker: MatomoTracker,
  ) {
    this.address = ''
  }

  ngOnInit() {
    this._map = this._mapService.getMap()
  }

  async options(value) {
    let isSelection = false
    if (this.results.some((obj) => obj.label == value)) {
      isSelection = true
      this._onSelectResult(value)
    }
    const provider = new OpenStreetMapProvider({
      params: {
        'accept-language': 'en', // render results in English
        countrycodes:
          'al,ad,at,az,by,be,ba,bg,hr,cy,cz,dk,ee,fi,fr,ge,de,gr,hu,is,ie,it,kz,xk,lv,li,lt,lu,mk,mt,md,mc,me,nl,no,pl,pt,ro,ru,sm,rs,sk,si,es,se,ch,tr,ua,gb,va', // limit search results to Canada & United States
      },
    })
    if (isSelection) {
      this.results = []
    } else {
      this.results = await provider.search({ query: value })
    }
  }

  goto() {
    if (!this.address) {
      return
    }
    this._loaderService.display(true)
    this._geocoder.geocode(this.address).subscribe(
      (location) => {
        this._map.fitBounds(location.viewBounds, {})
        this.address = location.address
        this._tracker.trackEvent('Search', 'Use searchbar', this.address)
        this._loaderService.display(false)
      },
      (error) => {
        console.warn('_addLayerWithActionVector', error)
        this._loaderService.display(false)
      },
    )
  }

  recenterMap() {
    this._mapService.recenter()
  }

  private _onSelectResult(result: any) {
    this.address = result
    this.goto()
  }
}
