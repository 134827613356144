import { animate, state, style, transition, trigger } from '@angular/animations'
import { NgClass } from '@angular/common'
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatTooltipModule } from '@angular/material/tooltip'
import { LayerInteractionService } from '@components/_panel-left/layers-tab/layers-interaction/layers-interaction.service'
import { UploadedLayersService } from '@components/_panel-left/layers-tab/uploaded-layers.service'
import { SelectionToolService } from '@components/_panel-left/tools-tab/selection-tools/service/selection-tool.service'
import { MAX_SURFACE_VALUE_CM } from '@core/constants/constant.data'
import { RASTER_TYPE, VECTOR_TYPE } from '@core/constants/layers.data'
import { InteractionService } from '@core/services/interaction.service'
import { LayersService } from '@core/services/layers.service'
import { SelectionScaleService } from '@core/services/selection-scale.service'
import { ToasterService } from '@core/services/toaster.service'
import { isNullOrUndefinedString } from '@services/core.utilities'
import { MatomoTracker } from 'ngx-matomo-client'
import { CalculationModuleService } from './calculation-module.service'
import { ImportCmInputsComponent } from './import-cm-inputs/import-cm-inputs.component'
import { CalculationModuleClass } from './service/calculation-module.class'
import { StandAloneCMsComponent } from './stand-alone-cms/stand-alone-cms.component'

@Component({
  standalone: true,
  selector: 'app-cms',
  templateUrl: 'calculation-modules.component.html',
  styleUrls: ['calculation-modules.component.css'],
  animations: [
    trigger('cmTrigger', [
      state('expanded', style({ opacity: 1 })),
      state('collapsed', style({ opacity: 0 })),
      transition('collapsed => expanded', animate('200ms 150ms linear')),
      transition('expanded => collapsed', animate('100ms linear')),
    ]),
  ],
  imports: [
    NgClass,

    //Forms
    FormsModule,
    ReactiveFormsModule,

    //Component
    StandAloneCMsComponent,
    ImportCmInputsComponent,

    //Tooltips
    MatTooltipModule,
  ],
})
export class CalculationModulesComponent implements OnInit, OnDestroy, OnChanges, OnDestroy {
  @Input() expanded
  @Input() selectionSurface

  maxSurfaceValueCM = MAX_SURFACE_VALUE_CM

  calculationModules$ = signal<CalculationModuleClass[]>([])
  categories$ = signal<string[]>([])
  waitingCM$ = signal<boolean>(true)

  cmSelected
  standaloneCMs = StandAloneCMsComponent

  progress = 0
  cmRunning = false
  inputs_categories = [
    { id: '0', name: 'Inputs', contains_component: false },
    { id: '1', name: 'Basic inputs', contains_component: false },
    { id: '2', name: 'Advanced inputs: (Level 1)', contains_component: false },
    { id: '3', name: 'Advanced inputs: (Level 2)', contains_component: false },
    { id: '4', name: 'Advanced inputs: (Level 3)', contains_component: false },
  ]
  layersFromType = []

  type_select = 'select'
  type_input = 'input'
  type_radio = 'radio'
  type_range = 'range'
  type_checkbox = 'checkbox'

  displayedCalculationModules$ = signal<CalculationModuleClass[]>([]) // Filtered Calculation modules (using search)
  searchCM = '' // Track search input

  private _components

  // Prefix session
  prefix_cm = ''
  private _nowDate = new Date()
  nowStr = this._nowDate.toLocaleString()

  constructor(
    public calculationModuleService: CalculationModuleService,
    public selectionToolService: SelectionToolService,
    public uploadedLayersService: UploadedLayersService,
    private _interactionService: InteractionService,
    private _layerInteractionService: LayerInteractionService,
    private _toasterService: ToasterService,
    private _layerService: LayersService,
    private _selectionScaleService: SelectionScaleService,
    private readonly _tracker: MatomoTracker,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this._subscribeEvents()
    this._updateCMs()
  }

  ngOnChanges(): void {
    if (this.cmSelected && this.selectionSurface <= 0) {
      this.cmSelected = undefined
      this.stopCM()
    }
  }

  ngOnDestroy() {
    this.stopCM()
    this.calculationModuleService.panelIsOpen.next(false)
  }

  changeValueFromInputArray(value, component) {
    component.input_value = value
  }

  changeValueFromInput(event, component) {
    const newValue = event.target.value
    if (newValue >= +component.input_min && newValue <= +component.input_max) {
      component.input_value = event.target.value
    } else {
      event.target.value = component.input_value
    }
  }

  runCM() {
    if (this.prefix_cm == '') {
      // default value is date time at local format
      this._nowDate = new Date()
      this.nowStr = this._nowDate.toLocaleString()
      this.prefix_cm = this.nowStr
    }

    this.cmSelected['cm_prefix'] = this.prefix_cm
    this.prefix_cm = ''
    this._components.forEach((comp) => {
      if (!isNullOrUndefinedString(comp.selected_value)) {
        comp.input_value = comp.selected_value
      }
    })

    this._setForExportCSV()

    this.calculationModuleService.currentCM$.set({
      cm: this.cmSelected,
      component: this._components,
    })
    this._tracker.trackEvent('CM', 'Calculation Module ran', this.cmSelected.cm_name)
    this.cmRunning = true
    this.calculationModuleService.cmRunning.next(true)
    this.cdRef.detectChanges() //used to force RUN CM button update
  }

  getComponentFiltered(id) {
    return this._components.filter((x) => x.input_priority === id)
  }

  validateAuthorizedScale(cm) {
    if (!isNullOrUndefinedString(cm.authorized_scale) && cm.authorized_scale.length >= 1) {
      if (
        cm.authorized_scale.filter(
          (x) => x === this._selectionScaleService.currentScaleLevel$().displayName,
        ).length >= 1
      ) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  private _setForExportCSV() {
    this._layerInteractionService.inputsCM.push({
      cm_name: this.cmSelected.cm_name,
      cm_prefix: this.cmSelected.cm_prefix,
      inputs: this._components,
      layersInputs: this.cmSelected.layers_needed.concat(this.cmSelected.vectorsNeeded),
      layersInputsDescription: this.cmSelected.type_layer_needed.concat(
        this.cmSelected.type_vectors_needed,
      ),
    })
  }

  stopCM() {
    this._interactionService.deleteCMTask()
    this.cmRunning = false
    this.cdRef.detectChanges() //used to force RUN CM button update
  }

  selectCM(cm) {
    if (this.selectionSurface <= 0) {
      this._toasterService.showDangerToaster(
        'Surface area must be greater than 0 to initiate a calculation module. <br/>Choose a valid surface area value and try again.',
      )
      return
    }
    if (this.selectionSurface > MAX_SURFACE_VALUE_CM) {
      this._toasterService.showToasterSurfaceLimit()
    } else {
      if (this.validateAuthorizedScale(cm)) {
        this.toggleCMPanel(true)
        this.waitingCM$.set(true)
        this.cmSelected = cm
        this.layersFromType = []
        if (!isNullOrUndefinedString(cm.type_layer_needed)) {
          cm.type_layer_needed.map((layer) => {
            this._setLayerFromType(layer, RASTER_TYPE)
          })
        }
        if (!isNullOrUndefinedString(cm.type_vectors_needed)) {
          cm.type_vectors_needed.map((layer) => {
            this._setLayerFromType(layer, VECTOR_TYPE)
          })
        }

        this.calculationModuleService
          .getCalculationModuleComponents(cm.cm_id)
          .then((values) => {
            this._components = values
            this._components.forEach((comp) => {
              comp['input_default_value'] = comp.input_value
              if (typeof comp.input_value == 'object') {
                comp.input_value = comp.input_value[0]
              }
            })
          })
          .then(() => {
            this._setComponentCategory()
          })
          .then(() => {
            this.waitingCM$.set(false)
          })
      } else {
        const scale_authorized = cm.authorized_scale.toString().replace(/,/g, ', ')
        this._toasterService.showDangerToaster(
          'Invalid scale level selected. <br/> Only <strong>' +
            scale_authorized +
            '</strong> can be chosen',
        )
      }
    }
  }

  toggleCMPanel(value: boolean) {
    if (!value) {
      this.searchCM = ''
      this.displayedCalculationModules$.set(this.calculationModules$())
    }
    this.calculationModuleService.panelIsOpen.next(value)
  }

  setLayerNeeded() {
    this.cmSelected.layers_needed = []
    this.layersFromType.map((layer) => {
      this.cmSelected.layers_needed.push({
        id: layer.layerSelected.id,
        name: layer.layerSelected.name,
        workspaceName: layer.layerSelected.workspaceName,
        download_url: layer.layerSelected.download_url,
        layer_type: layer.layerType, // is the key value define by CM dev
        // layer.layerSelected.layer_type is the type define for few layers to group in layer selection of cm
        data_type: layer.data_type,
      })
    })
  }

  handleImportedData(data: any) {
    this._updateFields(data)
  }

  searchCMs(query: string) {
    this.searchCM = query
    if (query.trim() === '') {
      this.displayedCalculationModules$.set(this.calculationModules$())
    } else {
      this.displayedCalculationModules$.set(
        this.calculationModules$().filter((cm: CalculationModuleClass) =>
          cm.cm_name.toLowerCase().includes(query.toLowerCase()),
        ),
      )
    }
  }

  private _subscribeEvents() {
    this.calculationModuleService.cmAnimationStatus.subscribe((data) => {
      this.progress = data
      if (this.progress !== 0) {
        this.cmRunning = true
        this.calculationModuleService.cmRunning.next(this.cmRunning)
      } else {
        this.cmRunning = false
        this.calculationModuleService.cmRunning.next(this.cmRunning)
      }
      this.cdRef.detectChanges()
    })
    this.calculationModuleService.panelIsOpen.subscribe((value) => {
      if (!value) {
        this._cmHidePanel()
      }
    })
  }

  private _updateCMs() {
    this._interactionService.deleteCMTask()
    this.calculationModuleService
      .getCalculationModuleServicesPromise()
      .then((cms) => {
        this.calculationModules$.set(cms)
        this.categories$.set(this.calculationModuleService.getCalculationModuleCategories(cms))
        this.displayedCalculationModules$.set(this.calculationModules$())
        this.waitingCM$.set(false)
      })
      .catch(() => {
        this.waitingCM$.set(false)
      })
  }

  private _setComponentCategory() {
    this.inputs_categories.map((input) => {
      input.contains_component = false
      if (this._components.filter((x) => x.input_priority === input.id).length >= 1) {
        input.contains_component = true
      }
    })
  }

  private _setLayerFromType(layerInput, data_type) {
    let layers: any[] = this._layerService
      .layers$()
      .filter((x) => x.layer_type === layerInput.type || x.workspaceName === layerInput.type)

    let hiddenLayerLayers: any[] = this._layerService
      .hiddenLayers$()
      .filter((x) => x.layer_type === layerInput.type || x.workspaceName === layerInput.type)
    layers = layers.concat(hiddenLayerLayers)

    const personalLayers = this.uploadedLayersService
      .personalLayers$()
      .filter((x) => x.layer === layerInput.type || x.layer_type === layerInput.type)
    layers = layers.concat(personalLayers)

    const sharedLayers = this.uploadedLayersService
      .shareLayers$()
      .filter((x) => x.layer === layerInput.type || x.layer_type === layerInput.type)
    layers = layers.concat(sharedLayers)

    if (layers.length >= 1) {
      this.layersFromType.push({
        layers: layers,
        layerSelected: layers[0],
        type_description: layerInput.description,
        data_type: data_type,
        layerType: layerInput.type,
        input_name: layerInput.name,
      })
    } else {
      const hiddenLayer = this._layerService
        .hiddenLayers$()
        .filter((hiddenLayer) => hiddenLayer.layer_type === layerInput.type)
      this.layersFromType.push({
        layers: hiddenLayer,
        layerSelected: hiddenLayer[0],
        type_description: layerInput.description,
        data_type: data_type,
        layerType: layerInput.type,
      })
    }

    this.setLayerNeeded()
  }

  private _cmHidePanel() {
    this.calculationModuleService.currentCM$.set(null)
    this.cmRunning = false
    this.calculationModuleService.cmRunning.next(this.cmRunning)
    this.cmSelected = undefined
    this._components = undefined
    this.cdRef.detectChanges() //used to force RUN CM button update
  }

  private _updateFields(uploadedData: any): void {
    if (!uploadedData || !this.cmSelected) return
    // Update components
    let inputsErrorCounter = 0
    let inputCounter = 0
    this._components.some((component) => {
      inputCounter++
      const indicator = component.input_name

      const correspondingField = uploadedData.find((line) => {
        return line.indicator.trim() === indicator.trim()
      })

      if (
        !isNullOrUndefinedString(correspondingField) &&
        correspondingField.hasOwnProperty('value')
      ) {
        const value = correspondingField.value
        component.input_value = value
      } else {
        inputsErrorCounter++
        console.warn('Input not found in imported data', indicator)
      }
    })

    // If not 1 input found a match, stops the import
    if (inputsErrorCounter != 0 && inputsErrorCounter == inputCounter) {
      this._toasterService.showDangerToaster(
        'Unable to import the inputs, make sure to select the csv file with the parameters of the active CM.',
      )
      return
    }

    // Update layers
    let layersErrorCounter = 0
    let layerCounter = 0
    this.layersFromType.some((layersTable) => {
      layerCounter++
      let foundMatch = false

      layersTable.layers.some((layer) => {
        if (!foundMatch) {
          const importedLayer = uploadedData.find((line) => {
            if (line.value) {
              return line.value.trim() === layer.name.trim()
            }
          })

          if (importedLayer) {
            layersTable.layerSelected = layer
            foundMatch = true
          }
        }
      })

      if (!foundMatch) {
        layersErrorCounter++
        console.warn(`Layer not found in imported data`, this.layersFromType)
      }
    })

    // If not 1 input found a match, stops the import
    if (layersErrorCounter != 0 && layersErrorCounter == layerCounter) {
      this._toasterService.showDangerToaster(
        'Unable to import the inputs, make sure to select the csv file with the parameters of the active CM.',
      )
      return
    } else if (layersErrorCounter > 0 || inputsErrorCounter > 0) {
      this._toasterService.showDangerToaster(
        'Input imported ! But least, 1 input cannot be imported',
      )
      return
    }

    this._toasterService.showToaster('Input imported !')
  }
}
