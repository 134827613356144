<div class="uk-card uk-card-default size">
  <div class="table-container">
    <!-- Material Table -->
    <table mat-table [dataSource]="uploadedFiles" class="mat-table">
      <!-- Filename Column -->
      <ng-container matColumnDef="filename">
        <th mat-header-cell *matHeaderCellDef>Filename</th>
        <td mat-cell *matCellDef="let upFile">{{ upFile.name }}</td>
      </ng-container>

      <!-- Layer Column -->
      <ng-container matColumnDef="layer">
        <th mat-header-cell *matHeaderCellDef>Layer type</th>
        <td mat-cell *matCellDef="let upFile">{{ upFile.layerName }}</td>
      </ng-container>

      <!-- Size Column -->
      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef>Size</th>
        <td mat-cell *matCellDef="let upFile">{{ upFile.size | number: '1.1-1' }} MB</td>
      </ng-container>

      <!-- Share Column -->
      <ng-container matColumnDef="share">
        <th mat-header-cell *matHeaderCellDef>Share</th>
        <td mat-cell *matCellDef="let upFile">
          @if (upFile.shared_with.length > 0) {
            Shared
          } @else {
            Private
          }
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="actions-header">
          <button mat-icon-button (click)="getFiles()" matTooltip="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </th>

        <td mat-cell *matCellDef="let upFile" class="actions-cell">
          <button mat-icon-button (click)="handleChildAction(upFile)" matTooltip="Edit">
            <mat-icon fontIcon="edit">settings</mat-icon>
          </button>
          <button mat-icon-button (click)="download(upFile)" matTooltip="Download">
            <mat-icon fontIcon="download"></mat-icon>
          </button>

          <button mat-icon-button (click)="openConfirmationDialog(upFile.id)" matTooltip="Delete">
            <mat-icon fontIcon="delete_forever"></mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Header and Row Definitions -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{ 'highlight-row': row.id === selectedFileToUpdate$()?.id }"
      ></tr>
    </table>
  </div>

  @if (this.selectedFileToUpdate$()) {
    <div class="layer-upload">
      <h3>Update {{ selectedFileToUpdate$().name }}</h3>
    </div>

    <div class="uk-margin-small upload-container">
      <!-- Row 1: File input and Layer type -->
      <div class="uk-grid-small uk-child-width-1-2@s uk-flex-middle" uk-grid>
        <div class="upload-file-container">
          <div class="uk-width-1-1@s uk-position-relative" uk-form-custom="target: true">
            <input
              class="uk-input upload-input"
              type="text"
              [placeholder]="selectedFileToUpdate$().name"
              disabled="true"
            />
          </div>
        </div>
        <div class="upload-layer-type-container">
          <select class="uk-select" id="layer-type" disabled="true">
            <option value="" disabled selected>
              [{{ selectedFileToUpdate$().layer_type }}] {{ selectedFileToUpdate$().layerName }}
            </option>
          </select>
        </div>
      </div>

      <!-- Row 2: Share layer -->
      <div class="uk-margin-top upload-share-container">
        <h4 class="share-title">Share</h4>
        <input
          type="text"
          class="uk-input share-input"
          placeholder="jane.doe@example.com; john.doe@example.com"
          [(ngModel)]="emailShareList$"
          (ngModelChange)="emailShareList$.set($event)"
          matTooltip="Write email addresses separated by a semicolon ` ; ` to make this layer accessible to other users. Removing a user from the list will remove their access to the layer."
          matTooltipPosition="above"
        />
        @if (isFileOk) {
          <p>Size: {{ file2Up.size / 1000 | number: '1.1-1' }} KB</p>
        }
      </div>
      @if (localhost) {
        <p class="errorMsgPersonalLayer">Sharing layers from localhost does not work</p>
      }

      <!-- Row 3: Upload button -->
      <div class="uk-flex uk-flex-right uk-margin-top upload-button-container">
        <button class="uk-button uk-button-secondary" (click)="cancelLayerUpdate()">cancel</button>
        <button
          class="uk-button uk-button-primary"
          [disabled]="ifEmailListChanged$()"
          (click)="updateListShare()"
          uk-icon="upload"
        >
          @if (isUploading) {
            <span uk-spinner></span>
          } @else {
            <span>Update layer</span>
          }
        </button>
      </div>
    </div>
  } @else {
    <div class="layer-upload">
      <h3>Add a personal layer</h3>
    </div>

    <div class="uk-margin-small upload-container">
      <!-- Row 1: File input and Layer type -->
      <div class="uk-grid-small uk-child-width-1-2@s uk-flex-middle" uk-grid>
        <div class="upload-file-container">
          <div class="uk-width-1-1@s uk-position-relative" uk-form-custom="target: true">
            <input
              id="layer-file"
              type="file"
              accept=".tif,.csv,.geojson"
              (change)="onFileChange($event)"
              #inputFile
              matTooltip="Select your layer file (.tif, .csv or .geojson)"
              matTooltipPosition="above"
            />
            <span uk-icon="icon: upload" class="upload-icon"></span>
            <input
              class="uk-input upload-input"
              type="text"
              placeholder="Select file"
              #inputFile2
              matTooltip="Select your layer file (.tif, .csv or .geojson)"
              matTooltipPosition="above"
            />
          </div>
        </div>
        <div class="upload-layer-type-container">
          <!-- <label for="layer-type">Select the layer type: </label> -->
          <select
            class="uk-select"
            [(ngModel)]="selectedLayer"
            id="layer-type"
            matTooltip="Select type of the layer"
            matTooltipPosition="above"
          >
            <option ngValue="geojson">geojson</option>
            @for (layer of layers$(); track layer.id) {
              <option [ngValue]="layer">[{{ layer.category }}] {{ layer.name }}</option>
            }
          </select>
        </div>
      </div>

      <!-- Row 2: Share layer -->
      <div class="uk-margin-top upload-share-container">
        <h4 class="share-title">Share</h4>
        <input
          type="text"
          class="uk-input share-input"
          placeholder="jane.doe@example.com; john.doe@example.com"
          [(ngModel)]="emailShareList$"
          (ngModelChange)="emailShareList$.set($event)"
          matTooltip="Write email addresses separated by a semicolon ` ; ` to make this layer accessible to other users. Removing a user from the list will remove their access to the layer."
          matTooltipPosition="above"
        />
        @if (isFileOk) {
          <p>Size: {{ file2Up.size / 1000 | number: '1.1-1' }} KB</p>
        }
      </div>
      @if (localhost) {
        <span class="errorMsgPersonalLayer">Sharing layer in localhost will not works!</span>
      }

      <!-- Row 3: Upload button -->
      <div class="uk-flex uk-flex-right uk-margin-top upload-button-container">
        <button
          class="uk-button uk-button-primary"
          [disabled]="!isFileOk || !selectedLayer"
          (click)="fileUpload()"
          uk-icon="upload"
        >
          @if (isUploading) {
            <span uk-spinner></span>
          } @else {
            <span>Upload layer</span>
          }
        </button>
      </div>
    </div>
  }
</div>
