import { HttpClient } from '@angular/common/http'
import { computed, Injectable, Signal, signal } from '@angular/core'
import { BaseService } from '@bases/base.service'
import {
  HiddenLayerClass,
  LayerClass,
} from '@components/_panel-left/layers-tab/layers-interaction/layers-interaction.class'
import { layerCategory } from '@components/_panel-left/layers-tab/layers-tab.interfaces'
import {
  CALCULATION_MODULE_CATEGORY,
  FORMAT_IMAGE,
  GEOSERVER_URL,
} from '@core/constants/constant.data'
import { HIDDEN_LAYERS_ARRAY, LAYERS_ARRAY } from '@core/constants/layers.data'
import { Dictionary } from '@core/models/dictionary.class'
import { categoryUsed } from '@core/models/enum'
import { LoaderService } from '@services/loader.service'
import { ToasterService } from '@services/toaster.service'
import { environment } from 'environments/environment'
import * as L from 'leaflet'
import 'proj4leaflet'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class LayersService extends BaseService {
  //@ToDo change in signal
  initialized$: BehaviorSubject<boolean> = new BehaviorSubject(false)

  layers$ = signal<LayerClass[]>([])
  hiddenLayers$ = signal<HiddenLayerClass[]>([])
  category$ = signal<layerCategory[]>([])
  search$ = signal<string>('')

  filteredLayers$: Signal<LayerClass[]> = computed(() => {
    return this.layers$().filter((layer) => {
      if (layer.name.toLowerCase().includes(this.search$().toLowerCase())) {
        return layer
      }
    })
  })

  layersLeaflet = new L.FeatureGroup()
  layersArray: Dictionary = new Dictionary([])

  constructor(http: HttpClient, loaderService: LoaderService, toasterService: ToasterService) {
    super(http, loaderService, toasterService)

    this.init()
  }

  async init() {
    this.layers$.set(await this._getLayerServices())
    this.hiddenLayers$.set(await this._getHiddenLayerServices())
    this.initialized$.next(true)
  }

  private _checkCategoryBaseOnLayersSelected() {
    this.category$().forEach((category) => {
      var numberOfLayerSelected = 0
      var numberOfLayerNotSelected = 0
      var numberOfLayerOfCategory = 0

      this.layers$().some((layer) => {
        if (category.name == layer.category) {
          numberOfLayerOfCategory++
          if (layer.isSelected) {
            numberOfLayerSelected++
          } else {
            numberOfLayerNotSelected++
          }
          return numberOfLayerOfCategory == this.category$().length
        }
      })
      if (numberOfLayerSelected == 0) {
        category.used = categoryUsed.UNSELECTED
      } else if (numberOfLayerOfCategory == numberOfLayerSelected) {
        category.used = categoryUsed.ALL_SELECTED
      } else {
        category.used = categoryUsed.SELECTED
      }
    })
  }

  private _getLayerServices(): Promise<LayerClass[]> {
    return Promise.resolve(
      LAYERS_ARRAY.filter(
        (layer) =>
          layer.projects?.includes(environment.appName) ||
          layer.category == CALCULATION_MODULE_CATEGORY ||
          //personal layer has non 0 id + only layers uploaded on same mode (localhost or dev) are shown
          (layer.id != 0 &&
            ((environment.apiUrl.includes('localhost') && layer.localhost) ||
              (!environment.apiUrl.includes('localhost') && !layer.localhost))),
      ),
    )
  }

  private _getHiddenLayerServices(): Promise<HiddenLayerClass[]> {
    return Promise.resolve(HIDDEN_LAYERS_ARRAY)
  }

  // call once in map.service but not used
  getIsReadyToShowFeatureInfo(): boolean {
    let readyToShow = false
    if (this.layersArray.keys().length > 0) {
      readyToShow = true
    }
    return readyToShow
  }

  // @ToDo: not used but someone stated something...
  addLayerWithOrder(layer: any) {
    this.layersLeaflet.addLayer(<L.Layer>layer)
    console.warn('addLayerWithOrder layer:', layer)
    console.warn('addLayerWithOrder getLayers:', this.layersLeaflet.getLayers())
  }

  showOrRemoveLayer(action: string, map: any, order: number) {
    if (!this.layersArray.containsKey(action)) {
      this._addLayerWithAction(action, order)
    } else {
      this._removeLayer2(action)
    }
    map.fireEvent('didUpdateLayers', this.layersArray)

    this._checkCategoryBaseOnLayersSelected()
  }
  removeLayer(action: string, map: any) {
    if (this.layersArray.containsKey(action)) {
      this._removeLayer2(action)
    }
    map.fireEvent('didUpdateLayers', this.layersArray)
  }
  private _removeLayer2(action: string) {
    this._unsetLoadingLayerInteraction(action)
    // we get the layer we want to remove
    const layer = this.layersArray.value(action)
    // we remove this layer from map
    this.layersLeaflet.removeLayer(layer)
    // we destroy the layer
    this.layersArray.remove(action)
  }

  removeAllLayers() {
    this.layersLeaflet.clearLayers()
    this.layersArray = new Dictionary([])
  }

  showLayer(action: string, map: any, order: number) {
    if (!this.layersArray.containsKey(action)) {
      this._addLayerWithAction(action, order)
    }
    map.fireEvent('didUpdateLayers', this.layersArray)
  }

  private _addLayerWithAction(action: string, order: number) {
    let layer
    const option = {
      layers: environment.prefixWorkspaceName + action,
      format: FORMAT_IMAGE,
      transparent: true,
      version: '1.3.0',
      srs: 'EPSG:4326',
      zIndex: order,
    }
    layer = this.getTileLayer(option, action)
    this.layersLeaflet.addLayer(layer)
    this.layersArray.add(action, layer)
  }

  getTileLayer(option: any, action): any {
    const wms_request = L.tileLayer.wms(GEOSERVER_URL, option)
    wms_request.on('load', () => {
      this._unsetLoadingLayerInteraction(action)
    })
    wms_request.on('tileunload', () => {})
    wms_request.on('tileloadstart', () => {
      this._setLoadingLayerInteraction(action)
    })
    wms_request.on('tileerror', (error) => {
      console.warn('getTileLayer', error)
      this._unsetLoadingLayerInteraction(action)
      this.toasterService.showToaster('Error loading tiles for ' + action)
    })
    wms_request.on('loading', () => {})
    return wms_request
  }

  // @ToDo: remove those method
  private _setLoadingLayerInteraction(layer) {
    this.layers$().filter((l) => l.workspaceName === layer)[0].isLoading = true
  }

  private _unsetLoadingLayerInteraction(layer) {
    // Should be fine but if there is ever an issue with layers, check the shallow copy of spread operator
    this.layers$.update((layers) =>
      layers.map((l) => (l.workspaceName === layer ? { ...l, isLoading: false } : l)),
    )
  }
}
